import React from 'react';
import { useAuth } from '~/hooks/auth';

import AuthRoutes from './auth.routes';
import AppRoutes from './app.routes';

export default function RoutesControl() {
  const { user } = useAuth();

  return !user ? <AuthRoutes /> : <AppRoutes />;
}
