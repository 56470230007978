export default {
  colors: {
    primary: '#ECD2A6',
    secondary: '#9A6517',
    tertiary: '#88550A',

    input: '#E5D6C5',
    selected: '#C18F59',

    icon: '#CEB79F',

    title: '#2D2D2C',
    text: '#2D2D2C',

    error: '#ff0000',
  },
  fonts: {
    regular: 'Ubuntu',
    medium: 'Ubuntu-Medium',
    bold: 'Ubuntu-Bold',
  },
} as const;
