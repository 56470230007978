import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { isMobile } from 'react-device-detect';

import AppProvider from './hooks';
import RoutesControl from './routes';

import GlobalStyle from './styles/global';
import theme from './styles/theme';

import Mobile from './pages/Mobile';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppProvider>
        <BrowserRouter>
          {isMobile ? <RoutesControl /> : <Mobile />}
          <GlobalStyle />
        </BrowserRouter>
      </AppProvider>
    </ThemeProvider>
  );
}

export default App;
