import axios, { AxiosError, AxiosResponse } from 'axios';

interface IReponseError {
  message: string;
  status: string;
}

let isRefreshing = false;
let failedRequestQueue: {
  onSuccess: (token: string) => void;
  onFailure: (err: AxiosError) => void;
}[] = [];

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  response => {
    return response;
  },
  (error: AxiosError) => {
    const errorMsg = error.response?.data as IReponseError;

    if (error.response?.status === 401) {
      if (errorMsg.message === 'JWT Token expired') {
        const originalConfig = error.config as AxiosResponse;

        if (!isRefreshing) {
          isRefreshing = true;

          api
            .post('/sessions/refresh', {
              refresh_token: localStorage.getItem('@GrpsCestas:refreshtoken'),
            })
            .then(response => {
              const { token, refreshToken } = response.data;

              api.defaults.headers.common.Authorization = `Bearer ${token}`;

              localStorage.setItem('@GrpsCestas:refreshtoken', refreshToken);
              localStorage.setItem('@GrpsCestas:token', token);

              failedRequestQueue.forEach(request => request.onSuccess(token));
              failedRequestQueue = [];
            })
            .catch(err => {
              failedRequestQueue.forEach(request => request.onSuccess(err));
              failedRequestQueue = [];
            })
            .finally(() => {
              isRefreshing = false;
            });
        }

        return new Promise((resolve, reject) => {
          failedRequestQueue.push({
            onSuccess: (token: string) => {
              originalConfig.headers.Authorization = `Bearer ${token}`;

              resolve(api(originalConfig));
            },
            onFailure: (err: AxiosError) => {
              reject(err);
            },
          });
        });
      }

      if (errorMsg.message === 'JWT token is missing.') {
        localStorage.removeItem('@GrpsCestas:refreshtoken');
        localStorage.removeItem('@GrpsCestas:token');
        localStorage.removeItem('@GrpsCestas:user');

        window.location.replace('/');
      }

      if (errorMsg.message === 'Invalid JWT Token') {
        localStorage.removeItem('@GrpsCestas:refreshtoken');
        localStorage.removeItem('@GrpsCestas:token');
        localStorage.removeItem('@GrpsCestas:user');

        window.location.replace('/');
      }
    }

    return Promise.reject(error);
  },
);

export default api;
