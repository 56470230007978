import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import Loading from '~/components/Loading';

const FoodParcel = lazy(() => import('../pages/FoodParcel'));
const Details = lazy(() => import('../pages/Details'));

export default function AppRoutes() {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route index element={<FoodParcel />} />
        <Route path="/details" element={<Details />} />
      </Routes>
    </Suspense>
  );
}
