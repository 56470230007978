import React from 'react';

import logo from '~/assets/images/logo.png';

import { Container, Content, Description } from './styles';

export default function Mobile() {
  return (
    <Container>
      <Content>
        <img src={logo} alt="Logo Guararapes" width={280} />
        <Description>
          Este conteúdo encontra-se disponível apenas para dispositivos móveis.
        </Description>
      </Content>
    </Container>
  );
}
