import React from 'react';
import { ScaleLoader } from 'react-spinners';
import { useTheme } from 'styled-components';

import { Container } from './styles';

export default function Loading() {
  const { colors } = useTheme();

  return (
    <Container>
      <ScaleLoader
        height={48}
        width={10}
        radius={8}
        margin={2}
        color={colors.tertiary}
      />
    </Container>
  );
}
